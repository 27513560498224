import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import * as BiIcons from "react-icons/bi";
import { ProjectsData } from '../data/ProjectsData';
import { AnimationData } from '../data/AnimationData'


let data = AnimationData.pageWork;

function PageWork() {
    useEffect(() => {
        document.title = 'Ali Alsadiq | Work';
    }, []);




    return (
        <motion.section className="projects-page page-section" variants={data.workPage} initial='initial' animate="visible" exit='exit'>
            {ProjectsData.map((project, index) => {
                return (
                    <motion.div variants={data.projectSection} className={project.section_Cname} key={index} >

                        <motion.div variants={index % 2 ? data.linksSection : data.imageSection}
                            className="project-image">
                            <img src={project.image} alt="project mockup" />
                            <p className="project-tools">{project.tools}</p>
                        </motion.div>

                        <motion.div variants={index % 2 ? data.imageSection : data.linksSection}
                            className='links-container'>

                            <h2>{project.title}</h2>
                            <p>{project.overview}</p>

                            <a className='links' href={project.github_link} target="_blank" rel="noopener noreferrer">
                                <div>Github<FaIcons.FaGithub className='icon' /></div>
                            </a>
                            {project.site_link !== null &&
                                <a className='links' href={project.site_link} target="_blank" rel="noopener noreferrer">
                                    <div>Live
                                        <RiIcons.RiComputerLine className='icon ' />
                                    </div>
                                </a>
                            }
                            {project.caseStudy_link !== null &&
                                <Link className='links' onClick={() => { window.scrollTo({ top: 0, behavior: 'smooth' }) }} to={project.caseStudy_link}>
                                    <div >
                                        View Details <BiIcons.BiDetail className='icon' />
                                    </div>
                                </Link>
                            }

                        </motion.div>
                    </motion.div>

                )
            })
            }
        </motion.section >
    )
}


export default PageWork


