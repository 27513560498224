export const AnimationData = {
    navBar: {
        logo: {
            hidden: {
                x: '15vw',
                y: 20,
                scale: 1.6,
                opacity: 1,
            },
            visable: {
                x: '3vw',
                transition: {
                    deay: 0.2,
                    duration: 0.4,
                    type: 'tween',
                }
            },
            logoHover: {
                opacity: [1, 0.7, 1],
                scale: [1.6, 1.63, 1.6],
                transition: {
                    duration: 1.3,
                    repeat: Infinity,
                }
            }
        },
        logoPath: {
            hidden: {
                pathLength: 0
            },
            visable: {
                opacity: 1,
                pathLength: 1,
                transition: {
                    delay: .3,
                    duration: 1.3,
                    ease: 'easeIn'
                }
            },
            visableDelayed: {
                opacity: 1,
                pathLength: 1,
                transition: {
                    delay: 1.3,
                    duration: .5,
                    ease: 'easeIn'
                }
            }
        },
        menuIcon: {
            hidden: {
                x: '50vh',
                opacity: 0,
            },
            visable: {
                scale: 1,
                x: 0,
                opacity: 1,
                transition: {
                    delay: .3,
                    type: 'spring',
                    stiffness: 60,
                    mass: 2,
                    damping: 10
                }
            },
            menuHover: {
                opacity: [0.8, 0.9, 1, 0.9, 0.8],
                originZ: 0,
                rotateZ: [0, -5, -10, -5, 5, 10, 5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                transition: {
                    repeat: Infinity,
                    duration: 1.2,
                }
            }
        },
        top: {
            closed: {
                originX: 0.5,
                originZ: 0
            },
            opened: {
                rotateZ: -45,

            }
        },
        center: {
            closed: {
                opacity: 1,
                y: 0,
                transition: {
                    duration: 0.4,
                    ease: 'easeInOut',
                    type: 'spring',
                    stiffness: 90
                }
            },
            opened: {
                opacity: 0,
                y: '-100vh',
                transition: {
                    duration: 3.4,
                    ease: 'easeInOut'
                }
            }
        },
        bottom: {
            closed: {
                originX: 0.5,
                originZ: 0,
                y: 0
            },
            opened: {
                y: 12,
                rotateZ: 45,

            }
        },
        nav: {
            initial: {
                x: '25vw',
                opacity: 0,
                width: 0,
            },
            visible: {
                x: 0,
                width: 270,
                opacity: 1,
                transition: {
                    duration: 0.05,
                }
            },
            exit: {
                x: '25vw',
                opacity: 0,
                width: 0,
                transition: {
                    delay: 0.05,
                }
            }
        },
        itemsContainer: {
            initial: {
            },
            visible: {
                transition: {
                    staggerChildren: .07,
                    delayChildren: 0.05
                }
            },
            exit: {
                transition: {
                    staggerChildren: .05,
                    staggerDirection: -1
                }
            }
        },
        navItems: {
            initial: {
                x: '15vw',
                opacity: 0,
            },
            visible: {
                x: 0,
                opacity: 1,
                transition: {
                    type: 'spring',
                    mass: 2,
                }
            },
            exit: {
                x: '15vw',
                opacity: 0,
                transition: {
                    type: 'spring',
                    stifness: 160,
                    mass: 2,

                }
            }
        },
    },
    pageHome: {
        homePage: {
            initial: {
            },
            visable: {
                transition: {
                    staggerChildren: 0.6,
                }
            },
            exit: {
                transition: {
                    staggerChildren: .4,
                    staggerDirection: -1
                }
            }
        },
        introContainer: {
            initial: {
                y: '-30vh',

                scale: 0.8,
                opacity: 0
            },
            visable: {
                y: 0,
                scale: 1,
                opacity: 1,
                transition: {
                    duration: 0.5
                }
            },
            exit: {
                y: '-30vh',
                scale: 0.8,
                opacity: 0,
                transition: {
                    duration: 0.3
                }
            }
        },
        linksContainer: {
            // Wrapper for linksLeft  & LinksRight 
        },
        linksLeft: {
            initial: {
                x: '-25vh',

                opacity: 0
            },
            visable: {
                x: 0,

                opacity: 1,
                transition: {
                    type: 'spring',
                    stiffness: 130,
                    mass: 0.1,
                    damping: 6
                }
            },
            exit: {
                x: '-25vh',
                scale: 0.6,
                opacity: 0,
                transition: {
                    type: 'tween',
                    duration: 0.3
                }
            }
        },
        linksRight: {
            initial: {
                x: '25vh',

                opacity: 0
            },
            visable: {
                x: 0,

                opacity: 1,
                transition: {
                    type: 'spring',
                    stiffness: 130,
                    mass: 0.1,
                    damping: 6
                }
            },
            exit: {
                x: '25vh',
                scale: 0.6,
                opacity: 0,
                transition: {
                    type: 'tween',
                    duration: 0.3

                }
            }
        }
    },
    pageWork: {
        workPage: {
            initial: {
            },
            visible: {
                transition: {
                    staggerChildren: .4,
                },
            },

            exit: {
                transition: {
                    staggerChildren: .15,
                    staggerDirection: -1
                }
            }
        },
        projectSection: {
            initial: {
                y: '15vh',
            },
            visible: {
                y: 0,
                transition: {
                    duration: 0.4
                }
            },
            exit: {
                opacity: 0,
                scale: 0,
            }
        },
        imageSection: {
            initial: {
                x: -300,
                opacity: 0
            },
            visible: {
                x: 0,
                opacity: 1,
            },
            exit: {
                x: -300,
                opacity: 0
            }
        },
        linksSection: {
            initial: {
                x: 300,
                opacity: 0
            },
            visible: {
                x: 0,
                opacity: 1,
            },
            exit: {
                x: 300,
                opacity: 0
            }
        }
    },
    caseStudy: {
        sectionHeader: {
            initial: {

            },
            visible: {
                rotate: '-135deg',
                transition: {
                    type: 'spring',
                    mass: 2,
                }
            },
            exit: {
                rotate: '0deg',
                transition: {
                    type: 'spring',
                    mass: 2,
                }
            }
        },
        DesignSection: {
            initial: {
                opacity: 0,
                x: '-100vw',
                height: '0',
            },
            visible: {
                opacity: 1,
                height: '100%',
                x: 0,
                transition: {
                    duration: 0.3
                }
            },
            exit: {
                opacity: 0,
                x: '-100vw',
                height: '0',
            }
        },
        DevSection: {
            initial: {
                opacity: 0,
                x: '100vw',
                height: '0',
            },
            visible: {
                opacity: 1,
                height: '100%',
                x: 0,
                transition: {
                    duration: 0.3
                }
            },
            exit: {
                opacity: 0,
                x: '100vw',
                height: '0',
            }
        }
    }

}