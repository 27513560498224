import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { motion } from "framer-motion";
import { AnimationData } from '../data/AnimationData'
import { SidebarData } from '../data/SidebarData';

function NavBar() {
    const [isOpen, setIsOpen] = useState(false);
    let data = AnimationData.navBar

    function showSideBar() {
        setIsOpen(!isOpen);

        let main = document.getElementById("main-body");
        main.classList.add(`padding-${!isOpen}`);
        main.classList.remove(`padding-${isOpen}`);
    }
    return (
        <>
            <div className='navbar'>
                <NavLink className='logo-container' onClick={() => { window.scrollTo({ top: 0, behavior: 'smooth' }) }} to='/'>
                    <motion.svg className="logo"
                        variants={AnimationData.navBar.logo}
                        initial='hidden'
                        animate='visable'
                        whileHover='logoHover'
                        id="Layer_1" data-name="Layer 1" width="44" height="34">
                        <motion.path variants={data.logoPath} initial='hidden' animate='visable' className="cls-1" d="M30.06,23.91v2.16a4,4,0,0,1-2.31.69c-2.31,0-3.21-1.71-3.15-4.05v-.66H17.94v4.44H15.63V22.05H12.9V19.89h2.73v-4a7.29,7.29,0,0,1,2.7-6l1.47,1.8a4.89,4.89,0,0,0-1.86,4.2v4H24.6V13.38A6.41,6.41,0,0,0,17.88,7c-3.39,0-6.6,2.22-6.6,5.37a4.6,4.6,0,0,0,1,3.09l-1.71,1.44A6.28,6.28,0,0,1,9,12.39C9,8,13.23,4.83,17.88,4.83c5,0,9,3.3,9,8.46V23a1.44,1.44,0,0,0,1.44,1.62A2.38,2.38,0,0,0,30.06,23.91Z" transform="translate(-9.03 -4.83)" />
                        <motion.path variants={data.logoPath} initial='hidden' animate='visable' className="cls-1" d="M35.58,26.76a3,3,0,0,1-2.94-3.27V5.19h2.19V23.28a1.39,1.39,0,0,0,1.41,1.5,2.17,2.17,0,0,0,1.56-.6v1.95A3.63,3.63,0,0,1,35.58,26.76Z" transform="translate(-9.03 -4.83)" />
                        <motion.path variants={data.logoPath} initial='hidden' animate='visable' className="cls-1" d="M39.81,10.05a1.65,1.65,0,1,1,3.3,0,1.65,1.65,0,0,1-1.65,1.65A1.66,1.66,0,0,1,39.81,10.05Zm3.51,16.71a3,3,0,0,1-2.94-3.27V15.9h2.16v7.38A1.4,1.4,0,0,0,44,24.78a2.17,2.17,0,0,0,1.56-.6v1.95A3.74,3.74,0,0,1,43.32,26.76Z" transform="translate(-9.03 -4.83)" />

                    </motion.svg>
                </NavLink>

                <button className='menu-bars'>
                    <motion.svg
                        variants={data.menuIcon}
                        onClick={showSideBar}
                        initial='hidden'
                        animate='visable'
                        whileHover='menuHover'
                        width="40" height="60">
                        <motion.rect variants={data.top} initial='closed' animate={isOpen ? "opened" : "closed"} className="cls-1" x="0.5" y="12.33" width="34.63" height="6.83" rx="3.42" />
                        <motion.rect variants={data.center} initial='closed' animate={isOpen ? "opened" : "closed"} className="cls-1" x="0.5" y="24.16" width="34.63" height="6.83" rx="3.42" />
                        <motion.rect variants={data.bottom} initial='closed' animate={isOpen ? "opened" : "closed"} className="cls-1" x="0.5" y="0.5" width="34.63" height="6.83" rx="3.42" />
                    </motion.svg>
                </button>
            </div>

            <motion.nav variants={data.nav} initial='initial' animate={isOpen ? 'visible' : 'exit'} className='nav-menu'>
                <motion.ul className='nav-menu-items' variants={data.itemsContainer} initial='initial' animate={isOpen ? 'visible' : 'exit'} onClick={showSideBar}>
                    {SidebarData.map((item, index) => {
                        return (
                            <motion.li variants={data.navItems} key={index} className={item.cName}>
                                {item.title !== 'Contact' ?
                                    <NavLink className='links' onClick={() => { window.scrollTo({ top: 0, behavior: 'smooth' }) }} to={item.path}>
                                        <div>{item.title} &nbsp;&nbsp; {item.icon}</div>
                                    </NavLink>
                                    :
                                    <a className='links' href={item.path} target="_blank" rel="noopener noreferrer">
                                        <div>{item.title} &nbsp;&nbsp; {item.icon}</div>
                                    </a>
                                }
                            </motion.li>
                        )
                    })}
                </motion.ul>
            </motion.nav>
            <div className={isOpen ? "nav-background" : "nav-background-hidden"}
                onClick={showSideBar}>

            </div>

        </>
    )
}

export default NavBar

