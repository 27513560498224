import React, { useEffect, useState } from "react";
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import * as SiIcons from "react-icons/si";
import * as MdIcons from "react-icons/md";
import * as GiIcons from "react-icons/gi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { motion } from "framer-motion";
import { AnimationData } from "../data/AnimationData";

const wireframesSlider = {
  // nextArrow: <MdIcons.MdArrowForwardIos />,
  // prevArrow: < MdIcons.MdArrowBackIos />,
  lazyLoad: "ondemand",
  arrows: false,
  dots: false,
  infinite: true,
  centerMode: true,
  draggable: true,
  swipeToSlide: true,
  slidesToShow: 2,
  slidesToScroll: 2,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
  ],
};

const Data = AnimationData.caseStudy;

function CaseStudy({ project }) {
  useEffect(() => {
    document.title = `${project.title} Case Study | Ali Alsadiq`;
  }, [project]);

  const [isDevOpen, setDevOpen] = useState(false);
  const [isDesignOpen, setDesignOpen] = useState(false);

  function openDesign() {
    setDesignOpen(!isDesignOpen);
    setDevOpen(false);
    let designSection = document.getElementsByClassName("design-section");
    setTimeout(() => {
      let position = designSection[0].offsetTop;
      window.scrollTo({ top: position - 80, left: 0, behavior: "smooth" });
    }, 330);
  }

  function openDev() {
    setDevOpen(!isDevOpen);
    setDesignOpen(false);
    let developmentSection = document.getElementsByClassName(
      "development-section"
    );

    setTimeout(() => {
      let position = developmentSection[0].offsetTop;
      window.scrollTo({ top: position - 80, left: 0, behavior: "smooth" });
    }, 330);
  }
  return (
    <motion.section
      initial={{ scale: 0, opacity: 0, y: "-100vh" }}
      animate={{ scale: [0, 0.2, 0.25, 1], opacity: 1, y: 0 }}
      transition={{ type: "tween", duration: 0.6 }}
      exit={{
        scale: [1, 0.5, 0.5, 0.5, 0.0],
        opacity: 0,
        y: ["-50vh", "50vh"],
        transition: { duration: 0.5 },
      }}
      className="case-study"
    >
      <div className="project-grid">
        <img
          className="project-image"
          src={project.image}
          alt="project mockup"
        />

        <section className="projects-details">
          <h1>{project.title}</h1>

          <div className="overview">
            <h3 className="overview-heading">
              Overview
              <MdIcons.MdOutlineSummarize className="icon" />
            </h3>
            <p>{project.summary}</p>

            <div className="tools">
              <h3 className="tools-heading">
                Project tools
                <GiIcons.GiPencilBrush className="icon" />
              </h3>
              <p className="project-tools">{project.tools}</p>
            </div>
          </div>
        </section>
      </div>

      {/* {project.wireframes !== null && (
        <section className="design-section">
          <div
            className={
              isDesignOpen ? "section-header active" : "section-header"
            }
            onClick={openDesign}
          >
            <h3>
              Wireframes
              <FaIcons.FaSitemap className="icon" />
            </h3>
            <svg
              stroke="currentColor"
              fill="currentColor"
              viewBox="0 0 448 512"
              className="plus-icon icon"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <motion.path
                variants={Data.sectionHeader}
                initial="initial"
                animate={isDesignOpen ? "visible" : "exit"}
                d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
              ></motion.path>
            </svg>
          </div>

          <motion.div
            variants={Data.DesignSection}
            initial="initial"
            animate={isDesignOpen ? "visible" : "exit"}
            className="section-body"
          >
            <a
              href={project.wireframes_link}
              className="links"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="nav-text">
                View on
                <SiIcons.SiAdobexd className="icon xd-icon" />
              </div>
            </a>

            <div className="wireframes-swipe">
              Swipe to view more slides{" "}
              <MdIcons.MdOutlineSwipe className="icon swipe-icon" />
            </div>
            <Slider className="mou-slider" {...wireframesSlider}>
              {project.wireframes.map((imagePath, index) => {
                return (
                  <div key={index}>
                    <img src={imagePath} alt="memo of understanding" />
                  </div>
                );
              })}
            </Slider>
          </motion.div>
        </section>
      )} */}

      {project.code !== null && (
        <section className="development-section">
          <div
            className={isDevOpen ? "section-header active" : "section-header"}
            onClick={openDev}
          >
            <h3>
              Development
              <FaIcons.FaCode className="icon" />
            </h3>

            <svg
              stroke="currentColor"
              fill="currentColor"
              viewBox="0 0 448 512"
              className="plus-icon icon"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <motion.path
                variants={Data.sectionHeader}
                initial="initial"
                animate={isDevOpen ? "visible" : "exit"}
                d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
              ></motion.path>
            </svg>
          </div>
          <motion.div
            variants={Data.DevSection}
            initial="initial"
            animate={isDevOpen ? "visible" : "exit"}
            className="section-body"
          >
            {project.code}
          </motion.div>
        </section>
      )}
      <div className="page-links">
        <a
          className="project-links"
          href={project.github_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div>
            <FaIcons.FaGithub className="social-icon" />
            GitHub
          </div>
        </a>
        {project.site_link !== null && (
          <a
            className="project-links"
            href={project.site_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <RiIcons.RiComputerLine className="comp-icon social-icon" />
              Live Site
            </div>
          </a>
        )}
      </div>
    </motion.section>
  );
}

export default CaseStudy;
