import React, { useEffect } from 'react';
import { motion } from "framer-motion";
import { NavLink } from 'react-router-dom';
import Designer from '../components/Designer';
import { AnimationData } from '../data/AnimationData'



function PageHome() {
    useEffect(() => {
        document.title = 'Ali Alsadiq | Web Developer';
    });
    let data = AnimationData.pageHome;

    return (
        <motion.section className='home-section' variants={data.homePage} initial="initial" animate='visable' exit='exit'>

            <motion.p variants={data.introContainer} className="intro">
                <div className='line1'>Hi, I'm</div>
                <div className='line2'>Ali Alsadiq</div>
                <div className='line3'>Web Developer <Designer /> </div>
                <div className='line4'>Based in Vancouver, BC</div>
            </motion.p>

            <motion.div variants={data.linksContainer} className="page-links">
                <NavLink className='links' onClick={() => { window.scrollTo({ top: 0, behavior: 'smooth' }) }} to="/about">
                    <motion.div variants={data.linksLeft}>
                        About Me
                    </motion.div>
                </NavLink>
                <NavLink className='links' onClick={() => { window.scrollTo({ top: 0, behavior: 'smooth' }) }} to="/work">
                    <motion.div variants={data.linksRight}>
                        My Projects
                    </motion.div>
                </NavLink>
            </motion.div >

        </motion.section>
    )
}

export default PageHome
